import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineInstagram, AiOutlineFacebook } from 'react-icons/ai';

import { YourChoiceMexico } from '../Stiky/YourChoiceMexico/YourChoiceMexico';
import { useScrollTo } from '../../hooks/useScrollTo';
import './Header.scss';

const Header = ({ onMenuItemClick }) => {
  const { scrollToPage } = useScrollTo();
  const socialsContent = (
    <div className="Header-socials">
      <a href="https:www.facebook.com/HogaresDeesaOficial/" target="_blank" rel="noreferrer">
        <AiOutlineFacebook />
      </a>
      <a href="https:www.instagram.com/hogares_deesa/" target="_blank" rel="noreferrer">
        <AiOutlineInstagram />
      </a>
    </div>
  );

  const handleMenuItemClick = (type) => {
    if (type) scrollToPage(type);
    if (onMenuItemClick) onMenuItemClick();
  };

  return (
    <header className="Header">
      <div className="Header-upper">
        <a href="/" className="Header-logo">
          <img src="/Assets/Iconos-y-logos/svg-logo-deesa.svg" alt="Logo deesa" />
        </a>
        <div className="Header-upper__mobile">
          <div className="Header-socials__container">{socialsContent}</div>
          <button onClick={() => handleMenuItemClick()} className="Header-toggle">
            <span />
          </button>
        </div>
      </div>
      <div className="Header-menu">
        <ul className="Header-menu__links">
          <li id="About">
            <Link onClick={() => handleMenuItemClick()} to="/nosotros">
              Nosotros
            </Link>
          </li>
          <li id="News">
            <a href="#desarrollos" onClick={() => handleMenuItemClick('#desarrollos')}>
              <span>
                <span className="hidden-sm">Nuestros</span> Desarrollos
              </span>
            </a>
          </li>
          <li id="Contact">
            <a onClick={() => handleMenuItemClick('#contacto')} href="#contacto">
              Contacto
            </a>
          </li>
          <li>
            <a className="phone" href="tel:4434714054">
              {' '}
              (443) 4714054
            </a>
          </li>
        </ul>
        <div className="Header-yourChoice">
          <YourChoiceMexico className="" />
        </div>
        {socialsContent}
        <a className="button-success" href="">
          Cotiza en linea
        </a>
      </div>
    </header>
  );
};

export default Header;
