import { FaWhatsapp } from 'react-icons/fa';
import { useForm } from 'react-hook-form';

import classNames from 'classnames';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ZoomIcon } from '../Icon';

import './SalesCenterForm.scss';
import axios from 'axios';
import { useState } from 'react';

const salesCenterFormSchema = yup.object({
  name: yup.string().required('Campo requerido'),
  phone: yup.string(),
  email: yup.string().email('Formato de email inválido').required('Campo requerido'),
  development: yup.string(),
  date: yup.string().optional(),
  time: yup.string().optional(),
  source: yup.string(),
});

export const SalesCenterForm = () => {
  const [isSuccessSent, setIsSuccesSent] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const { handleSubmit, register, formState, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(salesCenterFormSchema),
    defaultValues: {
      name: '',
      phone: '',
      development: '',
      date: null,
      time: '',
      source: '',
    },
    shouldUseNativeValidation: false,
  });

  const { isSubmitted } = formState;

  const onSubmit = async (data) => {
    const response = await axios.post(
      'https://cnergy-git-feature-deesa-mail-renderinc.vercel.app/api/mail-transport/deesa-handler',
      data,
    );

    setIsSuccesSent(response.data.success ?? false);
    setIsSubmitError(response.data.error);

    // Reset for after submission
    if (response.data.success) reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <input
        {...register('name')}
        className={classNames({
          error: formState.errors?.name,
        })}
        type="text"
        placeholder="Nombre completo"
      />
      {formState?.errors?.name && <small className="error">{formState?.errors?.name?.message}</small>}
      <input type="tel" {...register('phone')} placeholder="Teléfono" />
      {formState?.errors?.phone && <small className="error">{formState?.errors?.phone?.message}</small>}
      <input
        className={classNames({
          error: formState.errors?.email,
        })}
        type="email"
        {...register('email')}
        placeholder="Correo"
      />
      {formState?.errors?.email && <small className="error">{formState?.errors?.email?.message}</small>}
      <select {...register('development')} defaultValue="">
        <option disabled value="">
          Desarrollo de tu interés
        </option>
        {/* TODO: Load options from developments.json values */}
        <option value="Real castillejo">Real castillejo</option>
        <option value="Lomas de la maestranza">Lomas de la maestranza</option>
        <option value="Puerto diamante">Puerto diamante</option>
        <option value="Vistas la Huerta">Vistas la Huerta</option>
        <option value="Terrazas Quinceo">Terrazas Quinceo</option>
      </select>
      <div className="dateTime">
        <input
          {...register('date')}
          placeholder="Fecha"
          onFocus={(e) => (e.target.type = 'date')}
          onBlur={(e) => (e.target.type = 'text')}
        />
        <select {...register('time')} defaultValue="">
          <option disabled value="">
            Hora
          </option>
          <option value="9:00am - 10:00am">9:00am - 10:00am</option>
          <option value="10:00am - 11:00am">10:00am - 11:00am</option>
          <option value="11:00am - 12:00pm">11:00am - 12:00pm</option>
          <option value="12:00pm - 01:00pm">12:00pm - 01:00pm</option>
          <option value="01:00pm - 02:00pm">01:00pm - 02:00pm</option>
          <option value="02:00pm - 03:00pm">02:00pm - 03:00pm</option>
          <option value="03:00pm - 04:00pm">03:00pm - 04:00pm</option>
          <option value="04:00pm - 05:00pm">04:00pm - 05:00pm</option>
          <option value="05:00pm - 06:00pm">05:00pm - 06:00pm</option>
        </select>
      </div>
      <span>Selecciona el medio:</span>
      <div className="medio">
        <input type="radio" {...register('source')} id="zoom" className="input-hidden" value="Zoom" />
        <label htmlFor="zoom">
          <ZoomIcon width={60} className="m-0" />
        </label>
        <input type="radio" {...register('source')} id="whatsapp" value="WhatsApp" />
        <label htmlFor="whatsapp">
          <FaWhatsapp />
          <span>Whatsapp</span>
        </label>
        <input type="radio" {...register('source')} id="presencial" value="Presencial" />
        <label htmlFor="presencial">
          <span>Presencial</span>
        </label>
      </div>
      <p>Al enviar este formulario, usted acepta que ha leído y aceptado nuestra política de privacidad</p>
      {!isSuccessSent && <button type="submit">Enviar</button>}
      {isSuccessSent && isSubmitted && <b>!Formulario enviado!</b>}
      {!isSuccessSent && isSubmitError && <p>Error al enviar, intente nuevamente</p>}
    </form>
  );
};
