export const IconHouse = ({ width = 150, ...restProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 150 150" {...restProps}>
      <path
        fill="currentColor"
        d="m47.13,28.12c16.22-5.07,32.62-9.57,48.87-14.52,2.33-.83,4.32,1.01,5.72,2.63,13.34,14.95,27.08,29.55,40.28,44.62.02.84.05,2.53.07,3.37-2.37,3.15-6.45,3.8-9.82,5.4.23,12.15.09,24.32.1,36.48.14,1.7-.92,3.08-2.35,3.86-13.74,8.58-27.54,17.09-41.29,25.66-1.46.76-2.96,2.15-4.74,1.58-19.65-4.27-39.36-8.29-59.01-12.56-2.38-.38-5.49-1.6-5-4.6.02-13.52-.14-27.06.1-40.58-3.34-.31-8.12,1.23-9.79-2.69-1.22-2.41,1.01-4.49,2.35-6.25,11.58-14.06,22.73-28.52,34.51-42.4Zm-23.98,42.86c1.43.45,3.06.59,4.24,1.61,1.16,1.54.81,3.58.9,5.37-.13,12.86.14,25.73-.14,38.58,4.72,1.27,9.51,2.23,14.27,3.33-.04-6.93-.04-13.86-.04-20.79-.08-2.36,2.35-4.5,4.67-4.19,5.34.59,10.64,1.49,15.96,2.27,2.36.12,4.09,2.48,3.79,4.77-.03,7.62-.01,15.25-.03,22.87,4.52,1.1,9.04,2.19,13.65,2.88-.12-18.29-.12-36.58-.05-54.86-10.25-11.51-20.48-23.04-30.78-34.51-8.93,10.8-17.62,21.79-26.44,32.67Zm72.77,15.25c-3.25,1.48-5.25-2.06-7.19-3.96.09,14.49.16,28.97.02,43.46,11.76-7.35,23.47-14.79,35.38-21.89.05-10.2-.06-20.39.06-30.59-9.41,4.35-18.86,8.64-28.27,12.98Z"
      />
    </svg>
  );
};
