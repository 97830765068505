export const IconMoney = ({ width = 82.548, className, ...restProps }) => {
  return (
    <svg className={className} width={width} viewBox="0 0 82.548 82.548" {...restProps}>
      <path
        d="M207.406,153.841a11.974,11.974,0,0,0-5.627-2.211V141.8c3.056.564,5.384,2.521,5.384,4.772a1.794,1.794,0,0,0,3.589,0,7.728,7.728,0,0,0-3.346-6.194,11.976,11.976,0,0,0-5.627-2.211V135.36a1.794,1.794,0,1,0-3.589,0v2.811a11.974,11.974,0,0,0-5.627,2.211,7.406,7.406,0,0,0,0,12.388,11.975,11.975,0,0,0,5.627,2.211v9.825c-3.056-.564-5.383-2.521-5.383-4.772a1.794,1.794,0,0,0-3.589,0,7.728,7.728,0,0,0,3.346,6.194,11.976,11.976,0,0,0,5.627,2.211v2.81a1.794,1.794,0,1,0,3.589,0V168.44a11.974,11.974,0,0,0,5.627-2.211,7.406,7.406,0,0,0,0-12.388Zm-9.216-2.493c-3.056-.564-5.383-2.521-5.383-4.772s2.327-4.207,5.383-4.772Zm3.589,13.459v-9.543c3.056.564,5.384,2.521,5.384,4.771S204.836,164.243,201.78,164.807Z"
        transform="translate(-158.711 -112.032)"
        fill="currentColor"
      />
      <path
        d="M70.459,12.089a41.274,41.274,0,0,0-58.371,58.37,41.274,41.274,0,0,0,58.371-58.37ZM41.274,78.959A37.685,37.685,0,1,1,78.959,41.274,37.728,37.728,0,0,1,41.274,78.959Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        d="M121.1,78.221a1.795,1.795,0,0,0-2.8,2.245,26.623,26.623,0,0,1,5.9,16.824A26.9,26.9,0,1,1,110.58,73.876a1.795,1.795,0,0,0,1.774-3.12,30.524,30.524,0,1,0,8.75,7.465Z"
        transform="translate(-56.016 -56.016)"
        fill="currentColor"
      />
      <path
        d="M363.716,110.707a1.866,1.866,0,0,0-.1-.336,1.788,1.788,0,0,0-.389-.581,1.735,1.735,0,0,0-.273-.224,1.821,1.821,0,0,0-.309-.165,1.9,1.9,0,0,0-.335-.1,1.779,1.779,0,0,0-.7,0,1.811,1.811,0,0,0-.336.1,1.75,1.75,0,0,0-.31.165,1.73,1.73,0,0,0-.273.224,1.83,1.83,0,0,0-.388.581,1.855,1.855,0,0,0-.1.336,1.783,1.783,0,0,0-.036.352,1.755,1.755,0,0,0,.036.35,1.808,1.808,0,0,0,.1.335,1.773,1.773,0,0,0,.165.311,1.737,1.737,0,0,0,.5.5,1.753,1.753,0,0,0,.31.165,1.808,1.808,0,0,0,.336.1,1.765,1.765,0,0,0,.35.036,1.8,1.8,0,0,0,.352-.036,1.908,1.908,0,0,0,.335-.1,1.813,1.813,0,0,0,.309-.165,1.718,1.718,0,0,0,.273-.222,1.8,1.8,0,0,0,.526-1.269A1.808,1.808,0,0,0,363.716,110.707Z"
        transform="translate(-302.094 -91.647)"
        fill="currentColor"
      />
    </svg>
  );
};
