import { useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { YourChoiceMexicoModal } from './Modal/Modal';

export const YourChoiceMexico = ({ className = 'bbva' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleYourChoiceCloseClick = () => setIsModalOpen(false);
  const handleYourChoiceOpenClick = () => setIsModalOpen(true);

  const modalContent = createPortal(
    <CSSTransition in={isModalOpen} timeout={200} classNames="show" unmountOnExit>
      <YourChoiceMexicoModal onCloseClick={handleYourChoiceCloseClick} />
    </CSSTransition>,
    document.body,
  );

  return (
    <>
      <button className={className} onClick={handleYourChoiceOpenClick}>
        Tu opción México <img src="/Assets/Iconos-y-logos/svg-logo-bbva-deesa.svg" alt="" />
      </button>
      {modalContent}
    </>
  );
};
