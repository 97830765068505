import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';

const DEFAULT_SCROLL_TO_OPTIONS = { smooth: true, duration: 700 };

/**
 * Use `useScrollTo` to perform scroll actions such as scrolling to a specific element or a different page.
 * @author Alan Chávez<alan@renderinc.mx>
 */
export const useScrollTo = () => {
  const navigate = useNavigate();

  const scrollTarget = (target) => scroller.scrollTo(target, DEFAULT_SCROLL_TO_OPTIONS);

  const scrollToPage = (target) => {
    navigate(`/${target}`);
    scrollTarget(target.slice(1));
  };

  const scrollToTop = () => window.scrollTo(0, 0);

  return {
    scrollTarget,
    scrollToPage,
    scrollToTop,
  };
};
