import React from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import { FaWhatsapp } from 'react-icons/fa';
import { AppLayout } from '../Layouts/AppLayout';
import Mapa from '../Components/Mapa/Mapa';
import Slider from '../Components/Slider/Slider';
import Ubicacion from '../Components/Ubicacion/Ubicacion';
import { HighlightCard } from '../Components/HighlightCard/HighlightCard';
import { ZoomIcon, IconHouses, IconFamily, IconHouse } from '../Components/Icon';
import developmentsData from '../db/developments.json';
import { CoverVideo } from '../Components/CoverVideo/CoverVideo';
import { SalesCenterForm } from '../Components/SalesCenterForm/SalesCenterForm';

const getDevelopment = (slug) => developmentsData?.[slug] ?? {};

const getDevelopmentCoverImage = (slug) => {
  const [coverImage] = [getDevelopment(slug)?.coverImage ?? developmentsData[slug]?.images?.[0]];
  return coverImage || '';
};

const featuredDevelopment = getDevelopment('real-castillejo');

const Home = () => {
  return (
    <AppLayout>
      <div className="contenedor_portada">
        <div className="arrows"></div>
        <div className="tipos-creditos">
          <div>
            <img src="/Assets/Iconos-y-logos/svg-fovissste-credito-deesa.svg" alt="foviste" />
            <img src="/Assets/Iconos-y-logos/svg-infonavit-credito-deesa.svg" alt="infonavit" />
            <img src="/Assets/Iconos-y-logos/svg-bancario-credito-deesa.svg" alt="" />
          </div>
        </div>
        <Slider nav={false} pag={true}>
          <SwiperSlide>
            <div className="overlay" />
            <CoverVideo src="/Assets/deesa-cover-video.mp4" poster="/Assets/deesa-cover-thumbnail.jpeg" />
            <div className="texto-sobre">
              {/* TODO: Uncomment after final decission from product */}
              {/* <p>
                Hogares Deesa se constituyó desde el año 2011, desde entonces nos hemos dedicado a desarrollar viviendas
                de calidad, con las mejores ubicaciones y las mejores amenidades.
              </p> */}
              <a href="#desarrollos" className="button-success">
                Ver desarrollos
              </a>
            </div>
          </SwiperSlide>
          {/* TODO: Uncomment or remove if this slide has a purpose */}
          {/* <SwiperSlide>
            <img src="/Assets/Imagenes/img-real-castillejo-cover-deesa.jpg" alt="" />
            <div className="texto-sobre">
              <img src="/Assets/Iconos-y-logos/svg-frase-home-deesa.svg" alt="" />
              <p>
                Hogares Deesa se constituyó desde el año 2011, desde entonces nos hemos dedicado a desarrollar viviendas
                de calidad, con las mejores ubicaciones y las mejores amenidades.
              </p>
              <a href="" className="button-success-2">
                Separa tu casa
              </a>
            </div>
            <div className="icono-desarrollo">
              <img src="/Assets/Iconos-y-logos/svg-logo-real-castillejo-deesa.svg" alt="" />
              <div className="linea"></div>
              <p>
                Morelia <br /> Michoacán, México
              </p>
            </div>
          </SwiperSlide> */}
        </Slider>
      </div>
      <div className="contenedor_nosotros" id="nosotros">
        <div className="left">
          <CoverVideo src="/Assets/deesa-nosotros.mp4" className="video-cover" />
        </div>
        <div className="right">
          <img className="back-ventas" src="/Assets/Imagenes/img-back-deesa.jpg" alt="" />
          <div className="texto">
            <div className="titulo">
              <img className="ornamento-title" src="/Assets/Iconos-y-logos/svg-ornamento-deesa.svg" alt="" />
              <h2>12 Años</h2>
            </div>
            <div className="texto__description">
              <h4>de experiencia inmobiliaria</h4>
              <p>
                En Hogares Deesa, estamos comprometidos contigo, por ello es que desarrollamos proyectos innovadores,
                incorporando eco tecnologías que hacen sustentables nuestros desarrollos, sin dejar a un lado el diseño
                funcional que nos caracteriza. Conocemos y comprendemos las necesidades de las familias y por ello
                trabajamos día a día para ofrecerte las mejores alternativas y así comiences a escribir una nueva
                historia.
              </p>
              <Link to="/nosotros" className="button-success">
                Conoce más
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="contenedor_cards">
        <img className="ornamento left" src="/Assets/Imagenes/svg-ornamento3-deesa.svg" alt="" />
        <div className="leftcards">
          <HighlightCard
            bgImage="/Assets/Imagenes/img-hight-desarrollos-deesa.png"
            icon={<IconHouses />}
            text="+15"
            subtext="Desarrollos"
          />
          <HighlightCard
            bgImage="/Assets/Imagenes/img-hight-familias-deesa.png"
            icon={<IconFamily />}
            text="+3300"
            subtext="Familias felices"
          />
          <HighlightCard
            bgImage="/Assets/Imagenes/img-hight-casas-deesa.png"
            icon={<IconHouse />}
            text="+3340"
            subtext="Casas entregadas"
          />
        </div>
        <div className="rightcards">
          <img src="/Assets/Iconos-y-logos/svg-logo-blanco-deesa.svg" alt="" />
        </div>
        <img className="ornamento" src="/Assets/Imagenes/svg-ornamento4-deesa.svg" alt="" />
      </div>
      <div className="contenedor_desarrollo" id="desarrollos">
        <img className="banderines" src="/Assets/Iconos-y-logos/svg-banderines-deesa.svg" alt="" />
        <Slider nav={false} pag={true} className="images-carousel">
          <SwiperSlide
            className="justify-start"
            style={{
              backgroundImage: `url(${getDevelopmentCoverImage('real-castillejo')})`,
            }}>
            <div className="overlay" />
            <div className="texto-sobre relative">
              <img className="object-contain" src="/Assets/Iconos-y-logos/svg-logo-real-castillejo-deesa.svg" alt="" />
              <p>
                Real Castillejo es nuestro decimocuarto desarrollo, ubicado a tan solo 10 minutos del centro de la
                ciudad de Morelia y a 1 minuto de la universidad La Salle. Cuenta con 6 prototipo de viviendas
                unifamiliares.
              </p>
              <Link to="/real-castillejo" className="button-success">
                Conocer el desarrollo
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="justify-start"
            style={{
              backgroundImage: `url(${getDevelopmentCoverImage('lomas-maestranza')})`,
            }}>
            <div className="overlay" />
            <div className="texto-sobre relative">
              <img
                className="object-contain"
                src="/Assets/Iconos-y-logos/Desarrollos/svg-lomas-de-la-maestranza-blanco-deesa.svg"
                alt=""
              />
              <p>
                El desarrollo cuenta con áreas verdes para que disfrutes con tu familia momentos increíbles al aire
                libre ¡Lomas de las Maestranza, tu nuevo Hogar!
              </p>
              <Link to="/lomas-maestranza" className="button-success">
                Conocer el desarrollo
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="justify-start"
            style={{
              backgroundImage: `url(${getDevelopmentCoverImage('puerto-diamante')})`,
            }}>
            <div className="overlay" />
            <div className="texto-sobre relative">
              <img
                className="object-contain"
                src="/Assets/Iconos-y-logos/Desarrollos/svg-puerto-diamante-deesa.svg"
                alt=""
              />
              <p>
                Puerto Diamante es un desarrollo ubicado en la ciudad de Lázaro Cárdenas, Michoacán, con la mejor
                ubicación, a tan solo 1 minuto de plaza las Américas, el cual cuenta con viviendas unifamiliares con 3
                prototipos diferentes. El complejo cuenta con áreas verdes para que disfrutes con tu familia momentos
                increíbles al aire libre. "Piensa en Hogar, vive en Puerto Diamante"
              </p>
              <Link to="/puerto-diamante" className="button-success">
                Conocer el desarrollo
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="justify-start"
            style={{
              backgroundImage: `url(${getDevelopmentCoverImage('vistas-huerta')})`,
            }}>
            <div className="overlay" />
            <div className="texto-sobre relative">
              <img
                className="object-contain"
                src="/Assets/landings/vistas-huerta/svg-vistas-la-huerta-blanco-deesa.svg"
                alt=""
              />
              <p>
                El desarrollo cuenta con áreas verdes para que disfrutes con tu familia momentos increíbles al aire
                libre. "¡Vistas la huerta, tu nuevo Hogar!"
              </p>
              <Link to="/vistas-huerta" className="button-success">
                Conocer el desarrollo
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="justify-start"
            style={{
              backgroundImage: `url(${getDevelopmentCoverImage('terrazas-quinceo')})`,
            }}>
            <div className="overlay" />
            <div className="texto-sobre relative">
              <img
                className="object-contain"
                src="/Assets/Iconos-y-logos/Desarrollos/svg-terrazas-quinceo-deesa.svg"
                alt=""
              />
              <p>
                El desarrollo cuenta con áreas verdes para que disfrutes con tu familia momentos increíbles al aire
                libre. "¡Terrazas Quinceo, tu nuevo Hogar!"
              </p>
              <Link to="/terrazas-quinceo" className="button-success">
                Conocer el desarrollo
              </Link>
            </div>
          </SwiperSlide>
        </Slider>
      </div>
      <div className="contenedor_form_ventas" id="contacto">
        <img className="back-ventas" src="/Assets/Imagenes/img-back-deesa.jpg" alt="" />
        <div className="left">
          <img src="/Assets/Imagenes/img-centro-de-ventas-deesa.jpg" alt="" />
        </div>
        <div className="right">
          <div className="titulo">
            <img className="ornamento-title" src="/Assets/Iconos-y-logos/svg-ornamento-deesa.svg" alt="" />
            <h1>Centro de ventas</h1>
            <h1>VIRTUAL</h1>
          </div>
          <p>Agenda una cita y recibe asesoría</p>
          <div className="form-ventas">
            <SalesCenterForm />
          </div>
        </div>
      </div>
      <Ubicacion />
      <Mapa position={featuredDevelopment?.latLng} markerName={featuredDevelopment?.name} />
    </AppLayout>
  );
};

export default Home;
