import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper } from 'swiper/react';
import { EffectFade, Autoplay, Navigation, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const DEFAULT_SLIDE_SPEED = 1300;
const DEFAULT_AUTOPLAY_DELAY = 4000;

const Slider = ({ effect, nav, pag, speed = DEFAULT_SLIDE_SPEED, children, ...restProps }) => {
  const [swiperInstance, setSwiperInstance] = useState(null)

  useEffect(() => {
    if(!swiperInstance) return;
    // Reset slider to first slide if children change
    swiperInstance.slideTo(0, 0)
  }, [children])

  return (
    <Swiper
      onSwiper={(swiper) => setSwiperInstance(swiper)}
      autoplay={{
        delay: DEFAULT_AUTOPLAY_DELAY,
        pauseOnMouseEnter: true,
      }}
      speed={speed}
      modules={[EffectFade, Navigation, Pagination, Autoplay]}
      spaceBetween={30}
      effect={effect}
      navigation={nav}
      pagination={pag}
      loop={true}
      change
      {...restProps}>
      {children}
    </Swiper>
  );
};

export default Slider;
