import * as yup from 'yup';

export const getTextField = (isRequired) => {
  let textSchema = yup.string();
  if (isRequired) return textSchema.required('Campo requerido');
  return textSchema;
};

export const getEmailField = (isRequired) => {
  return getTextField(isRequired).email('Formato de email no válido');
};
