import { useMemo } from 'react';
import classNames from 'classnames';

import './Steps.scss';

export const Steps = ({ currentStep, data = [] }) => {
  const activeStepIndex = useMemo(() => data?.findIndex(({ id }) => id === currentStep) ?? 0, [data, currentStep]);

  const getTrackProgress = () => `${(activeStepIndex / (data.length - 1)) * 100}%`;

  return (
    <div className="Steps">
      <div className="Steps-track">
        <div
          className="Steps-track__indicator"
          style={{
            width: getTrackProgress(),
          }}
        />
      </div>
      <div className="Steps-content">
        {data.map(({ label, id }, index) => (
          <div
            key={`${id}-${index}`}
            className={classNames('Steps-step', {
              'is-active': index <= activeStepIndex || currentStep === id,
            })}>
            <span className="Steps-step__label">{label}</span>
            <span className="Steps-step__number">{index + 1}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
