import { useScrollTo } from '../../../hooks/useScrollTo';
import './CotizadorOptions.scss';

export const CotizadorOptions = ({ onConsultCreditClick, onOurDevelopmentsClick }) => {
  const { scrollToPage } = useScrollTo();

  const handleOurDevelopmentsClick = () => {
    scrollToPage('#desarrollos');
    if (onOurDevelopmentsClick) onOurDevelopmentsClick();
  };

  return (
    <div className="CotizadorOptions">
      <h2>¿QUE DESEAS HACER?</h2>
      <div className="CotizadorOptions-cards">
        <div className="CotizadorOptions-cards__card" onClick={onConsultCreditClick}>
          <h4>CONSULTAR MI CRÉDITO</h4>
          <div className="credito">
            <img src="/Assets/Iconos-y-logos/svg-fovissste-credito-deesa.svg" alt="" />
            <img src="/Assets/Iconos-y-logos/svg-infonavit-credito-deesa.svg" alt="" />
            <img src="/Assets/Iconos-y-logos/svg-bancario-credito-deesa.svg" alt="" />
          </div>
        </div>
        <div className="CotizadorOptions-cards__card" onClick={handleOurDevelopmentsClick}>
          <h4>NUESTROS DESARROLLOS</h4>
        </div>
      </div>
    </div>
  );
};
