import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import { useCotizadorWizard } from '../useCotizadorWizardContext';
import { COTIZADOR_WIZARD_STEPS } from '../constants';

import developmentsData from '../../../../db/developments.json';
import { OdooAPI } from '../../../../utils';

const developmentsOptions = Object.values(developmentsData).map(({ name, odooName, logo }) => ({
  label: name,
  value: odooName,
  logo: logo.white,
}));

export const CotizadorSelectDevelopment = () => {
  const { getWizardStepData, setCurrentWizardStep, updateWizardStepData, getWizardData } = useCotizadorWizard();
  const defaultValues = getWizardStepData(COTIZADOR_WIZARD_STEPS.DEVELOPMENT);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { isValid },
    watch,
  } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    updateWizardStepData({
      data,
      step: COTIZADOR_WIZARD_STEPS.DEVELOPMENT,
    });
  };

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const goBack = () => setCurrentWizardStep(COTIZADOR_WIZARD_STEPS.PERSONAL_DETAILS);

  const handleSendForm = async () => {
    setIsSubmiting(true);
    setHasErrored(false);

    try {
      const partnerResponse = await OdooAPI.submitQuotationForm(getWizardData());
      if (partnerResponse?.error) throw Error();
      setIsSubmiting(false);
      setCurrentWizardStep(COTIZADOR_WIZARD_STEPS.SEND);
    } catch (error) {
      setIsSubmiting(false);
      setHasErrored(true);
    }
  };

  return (
    <div
      style={{
        width: '100%',
      }}>
      <div className="CotizadorWizard-subtitle">
        <p>
          En Hogar Deesa, estamos comprometidoscontigo,por ello es que desarrollamos proyectos innovadores, incorporando
          eco tecnologias que hacen sustentables nuestros desarrollos, sin dejar aun lado el diseño funcional que nos
          caracteriza.
        </p>
      </div>
      <form className="CotizadorWizard-form" onSubmit={handleSubmit(onSubmit)}>
        <legend>¿En Que Desarollo Estás Interesado?</legend>
        <div className="CotizadorWizard-cards CotizadorWizard-cards--sm">
          {developmentsOptions.map(({ label, value, logo }) => (
            <div
              key={label}
              className={classNames('CotizadorWizard-card', {
                'is-selected': defaultValues?.developmentName === value,
              })}>
              <input
                type="radio"
                {...register('developmentName', {
                  required: true,
                })}
                id={value}
                className="input-hidden"
                value={value}
              />
              <label htmlFor={value}>{logo ? <img src={logo} alt={value} /> : label}</label>
            </div>
          ))}
        </div>
        <br />
        <br />
        {hasErrored && (
          <p style={{ color: 'red', textAlign: 'center' }}>
            Lo sentimos, ocurrió un error al procesar su solicitud. Intente cambiar el número de teléfono o verifique
            que los datos proporcionados sean correctos
          </p>
        )}
        <div className="CotizadorWizard-actions">
          <button onClick={goBack} type="button">
            Atrás
          </button>
          <button className="primary" onClick={handleSendForm} disabled={!isValid || isSubmiting}>
            {!isSubmiting && 'Enviar'}
            {isSubmiting && 'Enviando...'}
          </button>
        </div>
      </form>
    </div>
  );
};
