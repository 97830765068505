import { cloneElement } from 'react';
import './HighlightCard.scss';

const DEFAULT_ICON_SIZE = 60;

/**
 * Represents a card capable to render an icon, text and subtext and a back image.
 */
export const HighlightCard = ({ text, subtext, icon, bgImage = '', ...restProps }) => {
  const renderIcon = () => {
    if (!icon) return null;

    return (
      <div className="HighlightCard__Icon">
        {cloneElement(icon, {
          width: DEFAULT_ICON_SIZE,
        })}
      </div>
    );
  };

  return (
    <div className="HighlightCard" {...restProps}>
      {/* TODO: Update with an image passed via props */}
      <img className="HighlightCard__Image" src={bgImage} alt="" />
      <div className="HighlightCard__Content">
        {renderIcon()}
        <div>
          <h3>{text}</h3>
          <p>{subtext}</p>
        </div>
      </div>
    </div>
  );
};
