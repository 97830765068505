import React, { useState } from 'react';
import './Prototypes.scss';
import classNames from 'classnames';
import Slider from '../../Slider/Slider';
import { SwiperSlide } from 'swiper/react';

export const Prototypes = ({ prototypes = {} }) => {
  const prototypesValues = Object.values(prototypes)?.filter((prototype) => prototype.available);
  const defaultPrototypeSlug = prototypesValues[0]?.slug;
  const [activeTab, setActiveTab] = useState(defaultPrototypeSlug);
  const currentPrototype = prototypes[activeTab];

  const handleTabClick = (slug) => setActiveTab(slug);

  return (
    <div className="Prototypes-container" id="prototipos">
      <h3>Prototipos</h3>
      <div className="Prototypes-models">
        <div className="Prototypes-tabs">
          {prototypesValues.map((prototype) => (
            <button
              key={prototype.slug}
              className={classNames('Prototypes-tab', {
                'is-active': prototype.slug === activeTab,
              })}
              onClick={() => handleTabClick(prototype.slug)}>
              {prototype.name}
            </button>
          ))}
        </div>
        <div className="Prototypes-panel">
          <div className="Prototypes-panel__image">
            {currentPrototype?.gallery?.length > 0 ? (
              <Slider nav={true} pag={false} style={{
                height: '600px',
                maxHeight: "100%"
              }}>
                {currentPrototype?.gallery.map((galleryImage, index) => (
                  <SwiperSlide key={`${activeTab}-slide-${index}`}>
                    <img style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center"
                    }} src={galleryImage} alt="" />
                  </SwiperSlide>
                ))}
              </Slider>
            ) : (
              <img src={currentPrototype?.image} alt="" />
            )}
          </div>
          <div className="Prototypes-panel__features">
            <h3>{currentPrototype?.name}</h3>
            <p>
              <b>Unifamiliar. </b>
              Total de metros cuadrados de construcción: {currentPrototype?.totalConstruction}m<sup>2</sup>
            </p>
            <br />
            <ul>
              {Object.entries(currentPrototype?.features).map(([featureKey, featureDescription]) => (
                <li key={`${currentPrototype.slug}-${featureKey}`}>
                  <img src={`/Assets/fichas-prototipos/${featureKey}.svg`} alt="" />
                  <span>{featureDescription}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
