import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Represents the modal and the content to be used by `YourChoiceMexico` component
 */
export const YourChoiceMexicoModal = ({ onCloseClick }) => {
  const handleInterestedClick = (event) => {
    console.log(event);
    onCloseClick?.();
  };

  return (
    <div className="contenedor-modal">
      <p className="closed" onClick={onCloseClick}>
        X
      </p>
      <div className="info">
        <div className="title">
          <h2>Tu opción México</h2>
          <p>El crédito para que los mexicanos residentes en EE. UU. compren una casa en México</p>
        </div>
        <div className="bottom">
          <div className="left">
            <h3>Tasa anual y mensualidades fijas</h3>
            <div className="porcentaje">
              <h4>10.70%</h4>
              <p>Tasa anual fija</p>
            </div>
            <div className="porcentaje">
              <h4>11.5%</h4>
              <p>CAT promedio sin IVA</p>
            </div>
            <p>Consulta más información sobre el CAT aquí</p>
          </div>
          <div className="right">
            <div className="carecteristicas">
              <img src="/Assets/Iconos-y-logos/bbva/svg-bbva-patrimonio-deesa.svg" alt="" />
              <p>Adquiere tu patrimonio en México</p>
            </div>
            <div className="carecteristicas">
              <img src="/Assets/Iconos-y-logos/bbva/svg-bbva-pagos-deesa.svg" alt="" />
              <p>Paga antes o liquida sin penalizaciones</p>
            </div>
            <div className="carecteristicas">
              <img src="/Assets/Iconos-y-logos/bbva/svg-bbva-tasa-deesa.svg" alt="" />
              <p>Tasa fija en pesos mexicanos</p>
            </div>
            <div className="carecteristicas">
              <img src="/Assets/Iconos-y-logos/bbva/svg-bbva-financiamiento-deesa.svg" alt="" />
              <p>Financiamiento hasta del 90%</p>
            </div>
            <div className="carecteristicas">
              <img src="/Assets/Iconos-y-logos/bbva/svg-creditos-deesa.svg" alt="" />
              <p>Créditos desde $50,000</p>
            </div>
          </div>
        </div>
        <Link to="/#contacto" onClick={handleInterestedClick} className="button-success-2">
          Estoy interesado
        </Link>
        <div className="iconos">
          <img src="/Assets/Iconos-y-logos/svg-logo-blanco-deesa.svg" alt="" />
          <img src="/Assets/Iconos-y-logos/svg-logo-bbva-deesa.svg" alt="" />
        </div>
      </div>
    </div>
  );
};
