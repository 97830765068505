import React, { useRef, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useKeyBindings, useLockBodyScroll, useOutsideClick } from 'rooks';
import { SlArrowDown } from 'react-icons/sl';

import classNames from 'classnames';

import { CotizadorOptions } from './CotizadorOptions/CotizadorOptions';
import CotizadorWizard from './CotizadorWizard';

const Cotizador = ({ isVisible }) => {
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const cotizadorRef = useRef(null);
  const creditOptionsRef = useRef(null);
  const wizardRef = useRef(null);
  const nodeRef = isInit ? wizardRef : creditOptionsRef;

  const handleShowDrawer = () => setIsDrawerExpanded(true);

  const handleCotizadorhide = () => {
    setIsDrawerExpanded(false);
    setIsInit(false);
  };

  // Close cotizador on Espace or outside click
  useKeyBindings({ Escape: handleCotizadorhide });
  useOutsideClick(cotizadorRef, handleCotizadorhide);

  // Lock scroll for a better user experience
  useLockBodyScroll(isDrawerExpanded);

  return (
    <>
      {/* TOGGLE */}
      <button
        onClick={handleShowDrawer}
        className={classNames(`Cotizador-toggle`, {
          'is-visible': isVisible,
        })}>
        Cotiza Tu Casa Ahora
      </button>
      {/* DRAWER */}
      <div
        className={classNames('Cotizador-drawer', {
          'is-expanded': isDrawerExpanded,
        })}
        ref={cotizadorRef}>
        <SwitchTransition>
          <CSSTransition
            nodeRef={nodeRef}
            key={!isInit ? 'options' : 'wizard'}
            timeout={200}
            classNames="fade"
            addEndListener={(node, done) => node?.addEventListener?.('transitionend', done, false)}>
            <div
              style={{
                height: '100%',
                flexGrow: 1,
              }}
              ref={nodeRef}>
              {!isInit ? (
                <CotizadorOptions
                  onConsultCreditClick={() => setIsInit(true)}
                  onOurDevelopmentsClick={handleCotizadorhide}
                />
              ) : (
                <CotizadorWizard onClose={handleCotizadorhide} />
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
        <button className="Cotizador-drawer__arrowDown" onClick={handleCotizadorhide}>
          <SlArrowDown />
        </button>
      </div>
    </>
  );
};

export default Cotizador;
