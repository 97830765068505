import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Nosotros from './Pages/Nosotros';
import DevelopmentPage from './Pages/RealCastillejo';
import './styles/App.scss';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/nosotros" element={<Nosotros />} />
      <Route path="/:slug" element={<DevelopmentPage />} />
    </Routes>
  );
}

export default App;
