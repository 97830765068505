export const IconMapMarker = ({ width = 43.688, ...restProps }) => {
  return (
    <svg viewBox="0 0 43.688 53.1" width={width} {...restProps}>
      <g>
        <path
          d="M28.544,1.3C16.543,1.3,6.7,10.8,6.7,22.367c0,9.238,13.21,24.521,18.909,30.737a3.975,3.975,0,0,0,5.871,0c5.7-6.13,18.909-21.5,18.909-30.737C50.388,10.711,40.546,1.3,28.544,1.3Zm.691,49.732a.9.9,0,0,1-1.381,0C23.623,46.456,9.722,30.742,9.722,22.367c0-10.016,8.461-18.045,18.822-18.045s18.822,8.116,18.822,18.045c0,8.375-13.9,24-18.132,28.665Z"
          transform="translate(-6.7 -1.3)"
          fill="currentColor"
        />
        <path
          d="M30.525,15.7a9.325,9.325,0,1,0,9.325,9.325A9.341,9.341,0,0,0,30.525,15.7Zm0,15.541a6.3,6.3,0,1,1,6.3-6.3A6.338,6.338,0,0,1,30.525,31.241Z"
          transform="translate(-8.681 -3.267)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
