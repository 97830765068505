export const COTIZADOR_WIZARD_STEPS = {
  CREDIT: 'credit',
  PERSONAL_DETAILS: 'personalDetails',
  DEVELOPMENT: 'development',
  SEND: 'send',
};

export const CREDIT_TYPE = {
  FOVISSSTE: 'Fovissste',
  INFONAVIT: 'Infonavit',
  BANCARIO: 'Bancario',
  PENSIONES: 'Pensiones',
  CONTADO: 'Contado',
};

export const initialStoreState = {
  cotizadorData: {
    currentStep: COTIZADOR_WIZARD_STEPS.CREDIT,
    data: {
      [COTIZADOR_WIZARD_STEPS.CREDIT]: {
        creditType: '',
      },
      [COTIZADOR_WIZARD_STEPS.PERSONAL_DETAILS]: {
        name: '',
        birthDate: '',
        gender: '',
        monthlyIncome: null,
        phone: '',
        email: '',
        ine: '',
        curp: '',
        socialSecurityNumber: '',
        corroboratedIncome: false,
        payStubs: false,
        payChecks: false,
      },
      [COTIZADOR_WIZARD_STEPS.DEVELOPMENT]: {
        developmentName: '',
      },
    },
  },
};
