import { StateMachineProvider, createStore } from 'little-state-machine';
import { COTIZADOR_WIZARD_STEPS, initialStoreState } from './constants';
import { CotizadorSelectCredit } from './CotizadorSelectCredit/CotizadorSelectCredit';

import './CotizadorWizard.scss';
import { Steps } from '../../shared/Steps/Steps';
import { useCotizadorWizard } from './useCotizadorWizardContext';
import { CotizadorPersonalDetails } from './CotizadorPersonalDetails/CotizadorPersonalDetails';
import { useEffect, useState } from 'react';
import { CotizadorSelectDevelopment } from './CotizadorSelectDevelopment/CotizadorSelectDevelopment';
import { CotizadorSuccessState } from './CotizadorSuccessState/CotizadorSuccessState';

createStore(initialStoreState);

const cotizadorWizardStepsData = [
  {
    id: COTIZADOR_WIZARD_STEPS.CREDIT,
    label: 'Tipo de crédito',
  },
  {
    id: COTIZADOR_WIZARD_STEPS.PERSONAL_DETAILS,
    label: 'Datos',
  },
  {
    id: COTIZADOR_WIZARD_STEPS.DEVELOPMENT,
    label: 'Desarrollo',
  },
];

const CotizadorWizard = ({ onClose }) => {
  const [isHeadingVisible, setIsHeadingVisible] = useState(true);
  return (
    <StateMachineProvider>
      <div className="CotizadorWizard">
        {isHeadingVisible && <h2 className="CotizadorWizard-title">COTIZA TU CASA AHORA</h2>}
        <div className="CotizadorWizard-content">
          <CotizadorWizardContent onClose={onClose} onWizardSubmit={() => setIsHeadingVisible(false)} />
        </div>
        <div className="CotizadorWizard-footer">
          <CotizadorWizardSteps data={cotizadorWizardStepsData} />
        </div>
      </div>
    </StateMachineProvider>
  );
};

const CotizadorWizardSteps = ({ ...restProps }) => {
  const { getCurrentWizardStep } = useCotizadorWizard();

  return <Steps currentStep={getCurrentWizardStep()} {...restProps} />;
};

const CotizadorWizardContent = ({ onWizardSubmit, onClose }) => {
  const { getCurrentWizardStep, resetWizardData } = useCotizadorWizard();
  const currentStep = getCurrentWizardStep();

  // Effect to reset the store so users can have a fresh experience again
  useEffect(() => {
    return () => resetWizardData();
  }, []);

  useEffect(() => {
    if (currentStep !== COTIZADOR_WIZARD_STEPS.SEND) return;
    if (onWizardSubmit) onWizardSubmit();
  }, [currentStep]);

  const renderWizardStepContent = (stepKey) => {
    const wizardStepsMap = {
      [COTIZADOR_WIZARD_STEPS.CREDIT]: <CotizadorSelectCredit />,
      [COTIZADOR_WIZARD_STEPS.PERSONAL_DETAILS]: <CotizadorPersonalDetails />,
      [COTIZADOR_WIZARD_STEPS.DEVELOPMENT]: <CotizadorSelectDevelopment />,
      [COTIZADOR_WIZARD_STEPS.SEND]: <CotizadorSuccessState onFinishClick={onClose} />,
    };

    return wizardStepsMap[stepKey] ?? null;
  };

  return renderWizardStepContent(currentStep);
};

export default CotizadorWizard;
