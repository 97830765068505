import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const DEFAULT_MAP_POSITION = [19.703851769142833, -101.15860588679269];
const DEFAULT_MAP_ICON_URL = '/Assets/Iconos-y-logos/svg-marker-castillejo-deesa.png';
const DEFAULT_MAP_MARKER_NAME = 'Deesa';

const Mapa = ({
  position = DEFAULT_MAP_POSITION,
  iconUrl = DEFAULT_MAP_ICON_URL,
  markerName = DEFAULT_MAP_MARKER_NAME,
}) => {
  let DefaultIcon = L.icon({
    iconSize: [64.5, 82.5],
    iconUrl,
  });
  L.Marker.prototype.options.icon = DefaultIcon;
  return (
    <div className="mapa">
      <MapContainer center={position} zoom={17} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://api.mapbox.com/styles/v1/renderinc/ckzncuetf000w14p58ovnu756/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmVuZGVyaW5jIiwiYSI6ImNrem5jcnZlbTVubG8yb3BocmVqOTc3dmkifQ.RuwNrYFP82Cv84vem46FKg"
        />
        <Marker position={position}>
          <Popup>{markerName}</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default Mapa;
