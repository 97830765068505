import React, { useEffect, useRef, useState } from 'react';

export const CoverVideo = React.forwardRef(({ src, poster, ...restProps }, forwardedRef) => {
  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;
    videoElement.src = src;
    videoElement?.load();
    const handleVideoLoaded = () => setIsVideoLoaded(true);
    videoElement.addEventListener('loadeddata', handleVideoLoaded, false);

    return () => {
      if (!videoElement) return;
      videoElement?.removeEventListener('loadeddata', handleVideoLoaded);
    };
  }, [src]);

  return (
    <video ref={videoRef} autoPlay loop muted src={src} poster={poster} {...restProps}>
      <source src={src} type="video/mp4" />
    </video>
  );
});
