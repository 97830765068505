export const IconFamily = ({ width = 150, ...restProps }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 150 150" {...restProps}>
    <path
      fill="currentColor"
      d="m42.8,11.1c4.17-.92,8.73-.05,12.22,2.44,3.41,2.37,5.79,6.18,6.41,10.29.71,4.42-.63,9.11-3.57,12.48-2.83,3.32-7.11,5.33-11.47,5.37-4.8.12-9.58-2.2-12.49-6.01-3.01-3.82-3.99-9.1-2.63-13.76,1.5-5.35,6.1-9.64,11.53-10.8Z"
    />
    <path
      fill="currentColor"
      d="m100.99,11.01c4-.76,8.3.11,11.64,2.44,3.76,2.55,6.28,6.84,6.65,11.38.41,4.32-1.11,8.78-4.1,11.94-2.78,3-6.79,4.83-10.88,4.91-4.75.17-9.49-2.04-12.46-5.74-2.96-3.59-4.1-8.55-3.07-13.08,1.24-5.92,6.27-10.77,12.22-11.85Z"
    />
    <path
      fill="currentColor"
      d="m26.37,48.08c2.55-2.6,6.15-4.1,9.79-4.12,6.49-.02,12.97-.01,19.46,0,3.67-.05,7.33,1.37,9.95,3.96,2.73,2.69,5.42,5.41,8.13,8.12-5.5.52-10.56,4.16-12.79,9.21-.99,2.1-1.25,4.45-1.24,6.75-2.17-1.33-4.85-2.02-7.35-1.3-4.11,1.04-7.05,5.43-6.42,9.63.26,1.75,1.12,3.38,2.36,4.62,3.55,3.63,7.06,7.29,10.62,10.91-.02,12.66,0,25.32-.01,37.98-.02,1.21.18,2.41.63,3.54-1.69,1.55-4.14,2.3-6.38,1.66-2.9-.74-5.11-3.57-5.05-6.58-.02-11.92,0-23.84-.01-35.77.08-1.13-.85-2.21-1.99-2.25-1.22-.09-2.31,1.06-2.19,2.27-.01,11.12,0,22.24,0,33.36,0,1.86.18,3.85-.76,5.55-1.24,2.53-4.21,4.07-6.99,3.53-3.15-.49-5.56-3.54-5.48-6.7-.01-23.1,0-46.21,0-69.31-2.94,2.88-5.86,5.77-8.82,8.64-1.87,1.83-4.86,2.32-7.24,1.26-1.99-.89-3.61-2.74-3.98-4.93-.42-2.16.23-4.52,1.8-6.08,4.64-4.66,9.3-9.3,13.94-13.95Z"
    />
    <path
      fill="currentColor"
      d="m84.36,47.84c2.56-2.51,6.14-3.89,9.71-3.88,6.47-.01,12.94-.01,19.42,0,3.66-.02,7.3,1.44,9.9,4.03,4.69,4.61,9.36,9.25,14.04,13.87,1.57,1.49,2.32,3.76,1.99,5.9-.29,2.29-1.87,4.32-3.93,5.32-2.48,1.18-5.63.57-7.55-1.36-2.87-2.84-5.7-5.72-8.57-8.56,2.73,14.62,5.49,29.24,8.22,43.86-2.79,0-5.58,0-8.38,0-.02,8.49,0,16.99-.01,25.48.01,2.25-1.14,4.48-3.04,5.7-2.02,1.36-4.8,1.41-6.88.16-2.05-1.19-3.32-3.53-3.29-5.89-.01-8.48,0-16.96,0-25.45-1.45,0-2.9,0-4.35,0-.01,8.48,0,16.95-.01,25.43.09,3.4-2.75,6.52-6.13,6.78-2.03.24-4.02-.63-5.46-2.02.83-1.82.61-3.84.63-5.79v-31.66c.05-1.2-.12-2.42.09-3.61,3.62-3.77,7.35-7.44,10.98-11.19,1.44-1.5,2.25-3.56,2.29-5.63.08-3-1.51-5.97-4.06-7.55-2.9-1.89-6.96-1.84-9.78.19.15-3.47-.88-6.93-2.79-9.82-2.64-3.56-6.78-5.98-11.23-6.29,2.74-2.67,5.46-5.36,8.21-8.03Z"
    />
    <path
      fill="currentColor"
      d="m72.08,59.8c5.51-1.51,11.77,1.6,13.9,6.89,2.39,5.31.14,12.12-4.94,14.96-4.83,2.98-11.66,1.77-15.2-2.66-3.66-4.23-3.66-11.06.02-15.29,1.6-1.91,3.8-3.3,6.21-3.91Z"
    />
    <path
      fill="currentColor"
      d="m49.77,77.28c.84-2.42,3.6-3.91,6.08-3.28,1.59.31,2.72,1.54,3.8,2.64,2.33,2.32,4.64,4.66,6.97,6.97,1.63,1.65,3.74,2.84,6.02,3.27,3.74.77,7.8-.43,10.55-3.09,2.9-2.87,5.78-5.78,8.68-8.66,1.81-1.85,5.03-1.95,6.96-.23,1.75,1.45,2.36,4.11,1.39,6.17-.42.88-1.16,1.54-1.84,2.23-3.72,3.76-7.42,7.52-11.14,11.28,0,13.08,0,26.16,0,39.23.11,3.28-3.36,5.98-6.51,5.33-2.46-.49-4.67-2.71-4.61-5.31-.01-5.11,0-10.23,0-15.35.03-.74-.16-1.88-1.13-1.81-.93-.01-1.14,1.08-1.12,1.81-.01,5.12,0,10.24,0,15.36.1,3.32-3.5,6.05-6.68,5.27-2.38-.56-4.51-2.73-4.44-5.28-.01-13.03,0-26.07,0-39.1-4.01-4.08-8.04-8.13-12.04-12.22-1.33-1.36-1.64-3.5-.94-5.24Z"
    />
  </svg>
);
