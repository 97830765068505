import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { YourChoiceMexico } from './YourChoiceMexico/YourChoiceMexico';

const WA_MESSAGE_LINK = `https://wa.me/5214434714054?text=Estoy%20interesado%20en%20sus%20desarrollos%2C%20y%20me%20gustar%C3%ADa%20recibir%20m%C3%A1s%20informaci%C3%B3n`;

const Stiky = () => {
  return (
    <>
      <div className="contenedor_stiky">
        {/* <div className="whatsapp">
          <a
            href={WA_MESSAGE_LINK}
            target="_blank"
            rel="noreferrer">
            <FaWhatsapp />
          </a>
        </div> */}
        <YourChoiceMexico />
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 30,
          right: 30,
          zIndex: 1001,
        }}>
        <a href={WA_MESSAGE_LINK} target="_blank">
          <img src="/Assets/WhatsAppButtonGreenSmall.svg" />
        </a>
      </div>
    </>
  );
};

export default Stiky;
