import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import { useCotizadorWizard } from '../useCotizadorWizardContext';
import { COTIZADOR_WIZARD_STEPS, CREDIT_TYPE } from '../constants';

const creditTypeOptions = [
  {
    id: CREDIT_TYPE.FOVISSSTE,
    logo: '/Assets/Iconos-y-logos/svg-fovissste-credito-deesa.svg',
  },
  {
    id: CREDIT_TYPE.INFONAVIT,
    logo: '/Assets/Iconos-y-logos/svg-infonavit-credito-deesa.svg',
  },
  {
    id: CREDIT_TYPE.BANCARIO,
    label: (
      <div className="border">
        <p>CRÉDITO BANCARIO</p>
      </div>
    ),
  },
  {
    id: CREDIT_TYPE.PENSIONES,
    label: <p>PENSIONES</p>,
  },
  {
    id: CREDIT_TYPE.CONTADO,
    label: <p>CONTADO</p>,
  },
];

export const CotizadorSelectCredit = () => {
  const { getWizardStepData, setCurrentWizardStep, updateWizardStepData } = useCotizadorWizard();
  const defaultValues = getWizardStepData(COTIZADOR_WIZARD_STEPS.CREDIT);
  const { watch, handleSubmit, register } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    const payload = {
      data,
      step: COTIZADOR_WIZARD_STEPS.CREDIT,
    };

    updateWizardStepData(payload);
    setCurrentWizardStep(COTIZADOR_WIZARD_STEPS.PERSONAL_DETAILS);
  };

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div>
      <div className="CotizadorWizard-subtitle">
        <p>
          En Hogares Deesa, estamos comprometidos contigo, por ello es que desarrollamos proyectos innovadores,
          incorporando eco tecnologias que hacen sustentables nuestros desarrollos, sin dejar a un lado el diseño
          funcional que nos caracteriza.
        </p>
      </div>
      <form className="CotizadorWizard-form">
        <legend>ELIGE EL TIPO DE CREDITO</legend>
        <div className="CotizadorWizard-cards">
          {creditTypeOptions.map(({ id, logo, label }) => (
            <div
              key={id}
              className={classNames('CotizadorWizard-card', {
                'is-selected': defaultValues?.creditType === id,
              })}>
              <input type="radio" {...register('creditType')} id={id} className="input-hidden" value={id} />
              <label htmlFor={id}>{logo ? <img src={logo} alt={id} /> : label}</label>
            </div>
          ))}
        </div>
      </form>
    </div>
  );
};
