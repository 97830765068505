export const IconHouses = ({ width = 150, ...restProps }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 150 150" {...restProps}>
    <path
      fill="currentColor"
      d="m60.76,16.1c8-2.5,16.09-4.72,24.11-7.16,1.15-.41,2.13.5,2.82,1.3,6.58,7.38,13.36,14.58,19.87,22.02,0,.41.02,1.25.03,1.66-1.17,1.55-3.18,1.87-4.85,2.66.11,5.99.04,12,.05,18,.07.84-.45,1.52-1.16,1.9-6.78,4.23-13.59,8.43-20.37,12.66-.72.37-1.46,1.06-2.34.78-9.7-2.11-19.42-4.09-29.12-6.2-1.17-.19-2.71-.79-2.47-2.27,0-6.67-.07-13.35.05-20.02-1.65-.15-4.01.61-4.83-1.33-.6-1.19.5-2.22,1.16-3.08,5.71-6.94,11.22-14.07,17.03-20.92Zm-11.83,21.15c.71.22,1.51.29,2.09.79.57.76.4,1.77.44,2.65-.06,6.35.07,12.7-.07,19.04,2.33.63,4.69,1.1,7.04,1.64-.02-3.42-.02-6.84-.02-10.26-.04-1.16,1.16-2.22,2.3-2.07,2.63.29,5.25.74,7.87,1.12,1.16.06,2.02,1.22,1.87,2.35-.01,3.76,0,7.52-.01,11.28,2.23.54,4.46,1.08,6.73,1.42-.06-9.02-.06-18.05-.02-27.07-5.06-5.68-10.1-11.37-15.19-17.03-4.41,5.33-8.69,10.75-13.05,16.12Zm35.91,7.52c-1.6.73-2.59-1.02-3.55-1.95.04,7.15.08,14.29,0,21.44,5.8-3.63,11.58-7.3,17.46-10.8.02-5.03-.03-10.06.03-15.09-4.64,2.15-9.31,4.26-13.95,6.4Z"
    />
    <path
      fill="currentColor"
      d="m22.92,85.52c8-2.5,16.09-4.72,24.11-7.16,1.15-.41,2.13.5,2.82,1.3,6.58,7.38,13.36,14.58,19.87,22.02,0,.41.02,1.25.03,1.66-1.17,1.55-3.18,1.87-4.85,2.66.11,5.99.04,12,.05,18,.07.84-.45,1.52-1.16,1.9-6.78,4.23-13.59,8.43-20.37,12.66-.72.37-1.46,1.06-2.34.78-9.7-2.11-19.42-4.09-29.12-6.2-1.17-.19-2.71-.79-2.47-2.27,0-6.67-.07-13.35.05-20.02-1.65-.15-4.01.61-4.83-1.33-.6-1.19.5-2.22,1.16-3.08,5.71-6.94,11.22-14.07,17.03-20.92Zm-11.83,21.15c.71.22,1.51.29,2.09.79.57.76.4,1.77.44,2.65-.06,6.35.07,12.7-.07,19.04,2.33.63,4.69,1.1,7.04,1.64-.02-3.42-.02-6.84-.02-10.26-.04-1.16,1.16-2.22,2.3-2.07,2.63.29,5.25.74,7.87,1.12,1.16.06,2.02,1.22,1.87,2.35-.01,3.76,0,7.52-.01,11.28,2.23.54,4.46,1.08,6.73,1.42-.06-9.02-.06-18.05-.02-27.07-5.06-5.68-10.1-11.37-15.19-17.03-4.41,5.33-8.69,10.75-13.05,16.12Zm35.91,7.52c-1.6.73-2.59-1.02-3.55-1.95.04,7.15.08,14.29,0,21.44,5.8-3.63,11.58-7.3,17.46-10.8.02-5.03-.03-10.06.03-15.09-4.64,2.15-9.31,4.26-13.95,6.4Z"
    />
    <path
      fill="currentColor"
      d="m98.59,85.52c8-2.5,16.09-4.72,24.11-7.16,1.15-.41,2.13.5,2.82,1.3,6.58,7.38,13.36,14.58,19.87,22.02,0,.41.02,1.25.03,1.66-1.17,1.55-3.18,1.87-4.85,2.66.11,5.99.04,12,.05,18,.07.84-.45,1.52-1.16,1.9-6.78,4.23-13.59,8.43-20.37,12.66-.72.37-1.46,1.06-2.34.78-9.7-2.11-19.42-4.09-29.12-6.2-1.17-.19-2.71-.79-2.47-2.27,0-6.67-.07-13.35.05-20.02-1.65-.15-4.01.61-4.83-1.33-.6-1.19.5-2.22,1.16-3.08,5.71-6.94,11.22-14.07,17.03-20.92Zm-11.83,21.15c.71.22,1.51.29,2.09.79.57.76.4,1.77.44,2.65-.06,6.35.07,12.7-.07,19.04,2.33.63,4.69,1.1,7.04,1.64-.02-3.42-.02-6.84-.02-10.26-.04-1.16,1.16-2.22,2.3-2.07,2.63.29,5.25.74,7.87,1.12,1.16.06,2.02,1.22,1.87,2.35-.01,3.76,0,7.52-.01,11.28,2.23.54,4.46,1.08,6.73,1.42-.06-9.02-.06-18.05-.02-27.07-5.06-5.68-10.1-11.37-15.19-17.03-4.41,5.33-8.69,10.75-13.05,16.12Zm35.91,7.52c-1.6.73-2.59-1.02-3.55-1.95.04,7.15.08,14.29,0,21.44,5.8-3.63,11.58-7.3,17.46-10.8.02-5.03-.03-10.06.03-15.09-4.64,2.15-9.31,4.26-13.95,6.4Z"
    />
  </svg>
);
