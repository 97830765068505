import React from 'react';
import { AppLayout } from '../Layouts/AppLayout';
import Slider from '../Components/Slider/Slider';
import { SwiperSlide } from 'swiper/react';
import { HighlightCard } from '../Components/HighlightCard/HighlightCard';
import { IconFamily, IconHouse, IconMoney } from '../Components/Icon';
import { CoverVideo } from '../Components/CoverVideo/CoverVideo';
const Nosotros = () => {
  return (
    <AppLayout>
      <div className="contenedor_portada nosotros">
        <div className="arrows"></div>
        <img className="ornamento-bottom" src="/Assets/Imagenes/svg-ornamento4-deesa.svg" alt="" />
        <Slider nav={false} pag={true}>
          <SwiperSlide
            style={{
              // TODO: Replace with real image
              backgroundImage: 'url(https://www.hogaresdeesa.com/wp-content/uploads/2019/10/terrazas2.png)',
            }}>
            <div className="overlay"></div>
            <div className="texto-sobre relative">
              <img src="/Assets/Iconos-y-logos/svg-frase-home-deesa.svg" alt="" />
              <p>
                Hogares Deesa se constituyó desde el año 2011, desde entonces nos hemos dedicado a desarrollar viviendas
                de calidad, con las mejores ubicaciones y las mejores amenidades.
              </p>
            </div>
          </SwiperSlide>
        </Slider>
      </div>
      <div className="contenedor_nosotros revert">
        <div className="left video relative">
          <CoverVideo
            src="/Assets/deesa-nosotros-empresa.mp4"
            poster="/Assets/deesa-cover-thumbnail.jpeg"
            className="video-cover"
          />
        </div>
        <div className="right concepto-nosotros relative">
          <img className="back-ventas" src="/Assets/Imagenes/img-back-deesa.jpg" alt="" />
          <div className="texto">
            <img className="logo-deesa" src="/Assets/Iconos-y-logos/svg-logo-deesa.svg" alt="Logo Deesa" />
            <h4>de experiencia inmobiliaria</h4>
            <p>
              Somos Hogares Deesa una empresa desarrolladora de viviendas con más de diez años de comercialización. La
              comodidad y exclusividad son nuestras prioridades, conócenos y experimenta un confort sin igual. Nosotros,
              Hogares Deesa, estamos comprometidos con la calidad de nuestras viviendas, por lo que nuestra misión es
              brindar viviendas de sumo confort a individuos y familias que busquen un hogar con materiales de primer
              nivel, excelente ubicación a través de la implementación de tecnologías de sustentabilidad energética.
            </p>
          </div>
        </div>
      </div>
      <div className="contenedor_mision">
        <img className="torre" src="/Assets/Imagenes/img-torre--deesa.png" alt="" />
        <div className="contenedor_mision-wrapper">
          <div className="mision-vision">
            <div className="vision">
              <h1>Visión</h1>
              <p>
                En Hogares Deesa, estamos comprometidos contigo, por ello es que desarrollamos proyectos innovadores,
                incorporando eco tecnologías que hacen sustentables nuestros desarrollos, sin dejar a un lado el diseño
                funcional que nos caracteriza. Conocemos y comprendemos las necesidades de las familias y por ello
                trabajamos día a día para ofrecerte las mejores alternativas y así comiences a escribir una nueva
                historia.
              </p>
            </div>
            <div className="mision">
              <h1>Misión</h1>
              <p>
                Lograr el posicionamiento de la empresa a nivel nacional en el ramo de la construcción de viviendas como
                resultado de nuestro impecable servicio y la satisfacción de nuestros clientes.
              </p>
            </div>
          </div>
          <div className="mision-highlights">
            <HighlightCard
              bgImage="/Assets/Imagenes/img-hight-familias-deesa.png"
              icon={<IconFamily />}
              text="+3300"
              subtext="Familias felices"
            />
            <HighlightCard
              bgImage="/Assets/Imagenes/img-hight-casas-deesa.png"
              icon={<IconHouse />}
              text="+3340"
              subtext="Casas entregadas"
            />
            <HighlightCard
              bgImage="/Assets/Imagenes/img-hight-desarrollos-deesa.png"
              icon={<IconMoney />}
              text="+3300"
              subtext="Créditos"
            />
          </div>
        </div>
        <img className="ornamento" src="/Assets/Imagenes/svg-ornamento8-deesa.svg" alt="" />
      </div>
      <div className="contenedor_nuestros_desarrollos">
        <h1>Nuestros Desarrollos</h1>
        <div className="desarrollos_iconos">
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-real-castillejo-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-lomas-de-la-maestranza-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-puerto-diamante-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-terrazas-quinceo-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-vistas-la-huerta-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-hacienda-real-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-paseo-esmeralda-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-mirador-de-la-cantera-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-los-cipreses-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-paseo-esmeralda-deesa-1.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-alameda-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-alborada-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-castellon-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-san-lorenzo-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/svg-punta-floresta-deesa.svg" alt="" />
          </div>
          <div>
            <img src="/Assets/Iconos-y-logos/Desarrollos/Grupo 1986.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="contenedor_familias">
        <img className="ornamento" src="/Assets/Imagenes/svg-ornamento9-deesa.svg" alt="" />
        <div className="left">
          <img className="img-text" src="/Assets/Iconos-y-logos/svg-haciendo-familias-felices-deesa.svg" alt="" />
          <p>
            En Hogares Deesa estamos comprometidos en satisfacer las necesidades de las familias mexicanas, por ello es
            nuestro deber brindarles el mejor trato posible para que una de las experiencias más importantes de la vida,
            como lo es adquirir un nuevo hogar, sea lo más cómodo, tranquilo y sobre todo valioso. Agradecemos a las
            miles de familias que han decidido conocer más de nuestro trabajo, gracias familia de Hogares Deesa.
          </p>
          <div className="reseñas">
            <img className="like" src="/Assets/Iconos-y-logos/svg-like-deesea.svg" alt="Corazon" />
            <Slider nav={false} pag={false}>
              <SwiperSlide>
                <div className="card-reseña">
                  <div
                    className="avatar"
                    style={{
                      backgroundImage:
                        'url(https://t3.ftcdn.net/jpg/04/34/34/44/360_F_434344497_MI0MOENy6EnBIJa2CD2hThDi0MaFjJNv.jpg)',
                    }}></div>
                  <div className="resena">
                    <p>"Gracias a la desarrolladora DEESA pude cumplir mi sueño de tener una casa"</p>
                    <span>Claudia / Real Castillejo</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card-reseña">
                  <div
                    className="avatar"
                    style={{
                      backgroundImage:
                        'url(https://t3.ftcdn.net/jpg/06/32/72/34/360_F_632723420_xEHJf1YY8tIX4iRV1V9lrkpxBl5ne0Wc.jpg)',
                    }}>
                    <img src="" alt="" />
                  </div>
                  <div className="resena">
                    <p>"Muy buena ubicación del desarrollo"</p>
                    <span>Areni / Puerto Diamante</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card-reseña">
                  <div
                    className="avatar"
                    style={{
                      backgroundImage:
                        'url(https://media.istockphoto.com/id/1371363080/photo/portrait-of-a-mature-latin-woman-at-home.webp?b=1&s=170667a&w=0&k=20&c=GIkB4i8DHE_4hwtM5p2d8lFFBi9TB7ZAyQWGCRy-xs4=)',
                    }}>
                    <img src="" alt="" />
                  </div>
                  <div className="resena">
                    <p>"Estoy satisfecha con el fraccionamiento, definitivamente lo recomendaría"</p>
                    <span>Brenda / Puerto Diamante</span>
                  </div>
                </div>
              </SwiperSlide>
            </Slider>
          </div>
        </div>
        <div className="right">
          <div className="grid-videos">
            {Array(6)
              .fill(null)
              .map((_, index) => {
                return (
                  <div key={index} className="contenido">
                    <img src={`/Assets/Imagenes/Nosotros/img_test_${index + 1}_deesa.png`} alt="" />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="contenedor_presencia">
        <img className="back-ventas" src="/Assets/Imagenes/img-back-deesa.jpg" alt="" />
        <div className="left">
          <img src="/Assets/Iconos-y-logos/svg-michoacan-deesa.svg" alt="" />
        </div>
        <div className="right">
          <h2>Presencia</h2>
          <p>
            Nuestro arduo trabajo en el estado de Michoacán ha sido caracterizado por viviendas de calidad y desarrollos
            sustentables, así, en Hogares Dessa nos sentimos orgullosos por pertenecer y ser un referente en el mercado
            inmobiliario dentro y fuera de la capital michoacana. Hogares Deesa está presente en ciudades como Lázaro
            Cárdenas, Morelia y Cd. Hidalgo.
          </p>
        </div>
      </div>
    </AppLayout>
  );
};

export default Nosotros;
