import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Cotizador from '../Components/Cotizador/Cotizador';
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';
import Stiky from '../Components/Stiky/Stiky';
import { useScrollTo } from '../hooks/useScrollTo';

const MAX_DESKTOP_VIEWPORT_BREAKPOINT = 1024;
const QUOTATION_NO_VISIBLE_VIEWPORT_WIDTH = 600;

export const AppLayout = ({ children }) => {
  const { scrollToTop } = useScrollTo();
  const [y, setY] = useState(window.scrollY);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isQuotationVisible, setIsQuotationVisible] = useState(window.innerWidth > QUOTATION_NO_VISIBLE_VIEWPORT_WIDTH);

  const handleNavigation = useCallback(
    (event) => {
      const window = event.currentTarget;
      if (y > window.scrollY) {
        if (window.scrollY < 80 && window.innerWidth > MAX_DESKTOP_VIEWPORT_BREAKPOINT) setIsQuotationVisible(false);
      } else if (y < window.scrollY) {
        if (window.scrollY > 50) setIsQuotationVisible(true);
      }
      setY(window.scrollY);
    },
    [y],
  );

  const handleResize = useCallback(() => setIsMenuOpen(false), []);

  const handleMenuItemClick = () => {
    if (window.innerWidth > MAX_DESKTOP_VIEWPORT_BREAKPOINT) return;
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  // Scroll to top once a page loads
  useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleNavigation, handleResize]);

  return (
    <div
      className={classNames('App', {
        'menu-open': isMenuOpen,
      })}>
      <Header onMenuItemClick={handleMenuItemClick} />
      <Stiky />
      {children}
      <Footer />
      {/* TODO: Uncomment when integration is done for this form */}
      <Cotizador isVisible={isQuotationVisible} />
    </div>
  );
};
