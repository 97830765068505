import React from 'react';

const Footer = () => {
  return (
    <footer>
      <img src="/Assets/Iconos-y-logos/svg-ionet-certificacion-deesa.svg" alt="" />
      <div className="texto">
        <img src="/Assets/Iconos-y-logos/svg-logo-blanco-deesa.svg" alt="" />
        <p>© Todos los derechos Reservados DEESA, Aviso de Privacidad y Confidencialidad.</p>
        <p>Periférico Paseo de la República 1482, El Mirador del Punhuato, 58249 Morelia, Michoacán, México.</p>
      </div>
      <img src="/Assets/Iconos-y-logos/svg-iso-9001-certificacion-deesa.svg" alt="iso 9001" />
    </footer>
  );
};

export default Footer;
