import React from 'react';
import '../../styles/_amenidades.scss';

const amenitiesMap = {
  'acceso-azotea': 'Acceso a azotea',
  'acceso-controlado': 'Acceso controlado',
  'area-comun': 'Área común',
  'areas-juegos': 'Áreas verdes y juegos infantiles',
  'calles-concreto': 'Calles de concreto',
  'cerco-perimetral': 'Cerco perimetral',
  'excelente-ubicacion': 'Excelente ubicación en la zona',
  'pet-friendly': 'Zona Pet Friendly',
  'servicios-ocultos': 'Servicios ocultos',
  'vigilancia-acceso-controlado': 'Cámaras de vigilancia y acceso controlado',
  alberca: 'Alberca',
  gym: 'Gimnasio',
  trotapista: 'Trotapista',
  'areas-verdes': 'Área verde',
  'areas-recreacion': 'Áreas de recreación',
  'banquetas-amplias':'Banquetas amplias',
  'alumbrado-publico': 'Alumbrado público',
  'cajones-visita': 'Cajones de visita',
  andadores: 'Andadores',
  vegetación: 'Vegetación',
  vigilancia: 'Vigilancia 24/7',
};

const Amenidades = ({ amenitiesList = [] }) => {
  return (
    <div className="amenidades">
      <h3>AMENIDADES</h3>
      <div className="contenedor-amenidades">
        <div className="list_amenidades">
          {amenitiesList?.map((amenityKey, index) => {
            return (
              <div key={`${index}-${amenityKey}`} className="icono">
                <h4>{amenitiesMap[amenityKey]}</h4>
                <p>
                  <img src={`/Assets/Amenidades/${amenityKey}.svg`} alt="" />
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Amenidades;
