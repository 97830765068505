import { useStateMachine } from 'little-state-machine';
import { initialStoreState } from './constants';

// StateMachine Actions
const cotizadorStateMachineActions = {
  setCurrentWizardStep: (state, payload) => {
    if (!payload) return state;

    return {
      ...state,
      cotizadorData: {
        ...state.cotizadorData,
        currentStep: payload,
      },
    };
  },
  updateWizardStepData: (state, payload) => {
    if (!payload.data) return state;

    return {
      ...state,
      cotizadorData: {
        ...state.cotizadorData,
        data: {
          ...state.cotizadorData.data,
          [payload.step]: payload.data,
        },
      },
    };
  },
  resetWizardData: (_, payload = initialStoreState) => payload,
};

export const useCotizadorWizard = () => {
  const { actions, getState } = useStateMachine(cotizadorStateMachineActions);

  const getWizardStepData = (wizardStep) => getState()?.cotizadorData?.data?.[wizardStep] ?? {};
  const getCurrentWizardStep = () => getState()?.cotizadorData?.currentStep;
  const getWizardData = () => getState()?.cotizadorData?.data ?? {};

  return {
    ...actions,
    getWizardStepData,
    getCurrentWizardStep,
    getWizardData,
  };
};
