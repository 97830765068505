import React from 'react';
import './Ubicacion.scss';

const Ubicacion = () => {
  return (
    <div className="Ubicacion">
      <img className="Ubicacion-decor Ubicacion-decor--left" src="/Assets/Imagenes/svg-ornamento5-deesa.svg" alt="" />
      <img className="Ubicacion-decor Ubicacion-decor--right" src="/Assets/Imagenes/svg-ornamento6-deesa.svg" alt="" />
      <div className="Ubicacion-container relative">
        <div className="Ubicacion-logo">
          <img src="/Assets/Iconos-y-logos/svg-logo-real-castillejo-deesa.svg" alt="" />
        </div>
        <div className="Ubicacion-content">
          <p>Visita nuestro showroom</p>
          <br />
          <div>
            <h3 className="m-0">
              <b>58880 Tarímbaro, 58880 Morelia, Mich.</b>
            </h3>
            <p>10:00 am | 8:00 pm Lun - Dom</p>
            <div className="Ubicacion-more">
              <span className="font-family-montserrat font-bold">Ver en:</span>
              {/* TODO: Add proper URL to redirect */}
              <a href="https://maps.app.goo.gl/97codKXgHMmTkfpQ9" target="_blank" rel="noreferrer">
                <img src="/Assets/Iconos-y-logos/svg-icon-maps.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ubicacion;
