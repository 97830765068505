import React, { useEffect } from 'react';
import { useParams, redirect } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube';
import { SwiperSlide } from 'swiper/react';

import { AppLayout } from '../Layouts/AppLayout';
import { IconMapMarker } from '../Components/Icon/IconMapMarker';
import { Prototypes } from '../Components/DevelopmentPageContent/Prototypes/Prototypes';
import Amenidades from '../Components/Amenidades/Amenidades';
import Mapa from '../Components/Mapa/Mapa';
import Slider from '../Components/Slider/Slider';
import developmentData from '../db/developments.json';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import '../styles/_real_castillejo.scss';

const RealCastillejo = () => {
  const params = useParams();
  const development = developmentData[params?.slug] ?? null;

  //   TODO: add not found page
  if (!development) return redirect('/404');

  const renderMasterplan = () => {
    if (!development?.masterplanImages?.length) return null;
    if (development?.masterplanImages?.length === 1)
      return <img className="imagen-desarrollo" src={development?.masterplanImages[0]} />;

    return (
      <div className="images-carousel">
        <Slider nav={false} pag={true}>
          {development?.masterplanImages?.map((masterplanImage) => (
            <SwiperSlide>
              <img className="imagen-desarrollo" src={masterplanImage} alt="" />
            </SwiperSlide>
          ))}
        </Slider>
      </div>
    );
  };

  return (
    <AppLayout>
      <div className="contenedor_portada contenedor_portada--desarrollo">
        <Slider nav={false} pag={true}>
          {development.images?.map((imageSrc, index) => (
            <SwiperSlide key={`slide-${index}`}>
              <div className="overlay" />
              <img src={imageSrc} />
            </SwiperSlide>
          ))}
        </Slider>
        <a href="#prototipos" className="button-success">
          Ver prototipos
        </a>
      </div>
      <div className="contenedor-video-descripcion">
        <div className="izquierdo-video-descripcion">
          <div className="izquierdo-video-descripcion-principal">
            <div className="contenedor-video">
              <img src="/Assets/Iconos-y-logos/svg-play-deesa.svg" />
              <div className="video">
                <div className="fondo_video"></div>
                <ReactPlayer
                  className="react-player"
                  url={`https://youtu.be/${development?.youtubeId}`}
                  controls={false}
                  playing={true}
                  loop={true}
                  muted={true}
                  width="100%"
                  height="100%"
                  showinfo={0}
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="derecho-video-descripcion">
          <img className="derecho-video_logo" src={development?.logo?.base} />
          <div className="parrafo-video-descripcion">
            <div
              dangerouslySetInnerHTML={{
                __html: development?.description,
              }}
            />
            {/* <div>
              <a href="#" className="button-success">
                Aparta tu casa ahora
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="datos-deesa">
        <div className="datos-deesa__container">
          <div className="datos-deesa-izquierdo">
            <div className="datos-deesa-izquierdo-principal">
              <p className="icono">
                <IconMapMarker />
              </p>
              <div className="deesa-ubicacion">
                <p dangerouslySetInnerHTML={{ __html: development?.addressLine }} />
                <p className="txt-img">
                  <span className="whitespace-nowrap">Ver en:</span>{' '}
                  <a href={development?.mapsUrl} target="_blank" rel="noreferrer">
                    <img
                      style={{
                        width: 150,
                      }}
                      src="Assets/Iconos-y-logos/svg-icon-maps.svg"
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="datos-deesa-derecho">
            <div className="datos-deesa-derecho-principal">
              <div className="datos-deesa__specs">
                <div className="spec-item">
                  <img src="Assets/Iconos-y-logos/Fichas prototipos/svg-metros-deesa.svg" />{' '}
                  <p>
                    Desde {development?.specifications?.surface} m<sup>2</sup>
                  </p>
                </div>
                <div className="spec-item">
                  <img src="Assets/Iconos-y-logos/Fichas prototipos/svg-recamaras-deesa.svg" />
                  <p>Hasta {development?.specifications?.bedrooms}</p>
                </div>
                <div className="spec-item">
                  <img src="Assets/Iconos-y-logos/Fichas prototipos/svg-baños-deesa.svg" />
                  <p>Hasta {development?.specifications?.bathrooms}</p>
                </div>
                {development?.specifications?.ecotechnology && (
                  <div className="spec-item">
                    <img src="Assets/Iconos-y-logos/Fichas prototipos/svg-ecotecnologias-deesa.svg" />
                    <p>Ecotecnologías</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {development?.virtualTourTop && (
        <div className="iframe">
          <iframe
            title="Tour virtual"
            className="iframe-video"
            src={development?.virtualTourTop}
            allow="autoplay"
            width="100%"
            height="100%"
            border="0px"
          />
        </div>
      )}

      <div>{renderMasterplan()}</div>
      {development?.prototypes && <Prototypes prototypes={development?.prototypes} />}
      <Amenidades amenitiesList={development?.amenities} />
      <div className='virtual-tour'>
        {development?.virtualTour &&(
          development?.virtualTour.map((url, index)=>{
            return(
              <div key={index} className="iframe">
                <iframe
                  title="Tour virtual"
                  className="iframe-video"
                  src={url}
                  allow="autoplay"
                  width="100%"
                  height="100%"
                  border="0px"
                />
              </div>
            )
          })
        )
        }
      </div>
      {development?.virtualTourCover && <img className="imagen-desarrollo" src={development?.virtualTourCover} />}
      <div className="ubicacion">
        <div className="ubicacion-wrapper">
          <div className="logo">
            <div className="logo-principal">
              <img src={development?.logo?.white} />
            </div>
          </div>
          <div className="ubicacion-principal">
            <div className="contenedor-ubicacion">
              <p dangerouslySetInnerHTML={{ __html: development?.addressLine }} />
            </div>
            <div className="see-in-map">
              <p>Ver en:</p>
              <a href={development?.mapsUrl} target="_blank" rel="noreferrer">
                <img
                  style={{
                    width: 150,
                  }}
                  src="/Assets/Iconos-y-logos/svg-icon-maps.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Mapa position={development?.latLng} markerName={development?.name} iconUrl={development?.markerUrl} />
    </AppLayout>
  );
};
export default RealCastillejo;
