import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';

import { useCotizadorWizard } from '../useCotizadorWizardContext';
import { COTIZADOR_WIZARD_STEPS, CREDIT_TYPE } from '../constants';
import { getEmailField, getTextField } from '../../../../utils';

import './CotizadorPersonalDetails.scss';

const personalDetailsSchema = yup.object({
  name: getTextField(true),
  birthDate: getTextField(true),
  gender: getTextField(true),
  monthlyIncome: yup.number().min(0),
  phone: getTextField(true),
  email: getEmailField(true),
  curp: yup.string().when('$creditType', {
    is: (creditType) => creditType === CREDIT_TYPE.FOVISSSTE,
    then: (schema) => schema.required(),
  }),
  socialSecurityNumber: yup.string().when('$creditType', {
    is: (creditType) => creditType === CREDIT_TYPE.INFONAVIT,
    then: (schema) => schema.required(),
  }),
  ine: yup.string().when('$creditType', {
    is: (creditType) => creditType === CREDIT_TYPE.CONTADO,
    then: (schema) => schema.required(),
  }),
});

export const CotizadorPersonalDetails = () => {
  const { getWizardStepData, setCurrentWizardStep, updateWizardStepData } = useCotizadorWizard();
  const { creditType } = getWizardStepData(COTIZADOR_WIZARD_STEPS.CREDIT);
  const defaultValues = getWizardStepData(COTIZADOR_WIZARD_STEPS.PERSONAL_DETAILS);

  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(personalDetailsSchema),
    context: {
      creditType,
    },
  });

  const goBack = () => setCurrentWizardStep(COTIZADOR_WIZARD_STEPS.CREDIT);
  const goNext = () => setCurrentWizardStep(COTIZADOR_WIZARD_STEPS.DEVELOPMENT);

  const onSubmit = (data) => {
    updateWizardStepData({
      data,
      step: COTIZADOR_WIZARD_STEPS.PERSONAL_DETAILS,
    });

    goNext();
  };

  return (
    <div className="CotizadorPersonalDetails">
      <form className="CotizadorWizard-form" onSubmit={handleSubmit(onSubmit)}>
        <legend>INGRESA TUS DATOS</legend>
        <div className="CotizadorPersonalDetails-wrapper">
          <div className="CotizadorPersonalDetails-group">
            <input
              autoComplete="off"
              className={classNames('Input', {
                'is-invalid': errors?.name,
              })}
              type="text"
              {...register('name')}
              placeholder="Nombre Completo"
            />
            <input
              autoComplete="off"
              className={classNames('Input', {
                'is-invalid': errors?.name,
              })}
              type="text"
              {...register('rfc')}
              placeholder="RFC"
            />
            <input
              autoComplete="off"
              className={classNames('Input', {
                'is-invalid': errors?.email,
              })}
              type="email"
              {...register('email')}
              placeholder="Email"
            />
          </div>
          <div className="CotizadorPersonalDetails-group">
            <input
              autoComplete="off"
              className={classNames('Input', {
                'is-invalid': errors?.birthDate,
              })}
              type="date"
              {...register('birthDate')}
              placeholder="Fecha de nacimiento"
            />
            <select
              autoComplete="off"
              className={classNames('Select Select--light', {
                'is-invalid': errors?.gender,
              })}
              {...register('gender')}>
              <option disabled value="">
                Género
              </option>
              <option value="MUJER">Femenino</option>
              <option value="HOMBRE">Masculino</option>
            </select>
            {creditType === CREDIT_TYPE.FOVISSSTE && (
              <input
                autoComplete="off"
                className={classNames('Input', {
                  'is-invalid': errors?.curp,
                })}
                type="text"
                {...register('curp')}
                placeholder="CURP"
              />
            )}
            {creditType === CREDIT_TYPE.INFONAVIT && (
              <input
                autoComplete="off"
                className={classNames('Input', {
                  'is-invalid': errors?.socialSecurityNumber,
                })}
                type="text"
                {...register('socialSecurityNumber')}
                placeholder="Número de seguro social"
              />
            )}
            {creditType === CREDIT_TYPE.CONTADO && (
              <input
                autoComplete="off"
                className={classNames('Input', {
                  'is-invalid': errors?.ine,
                })}
                type="text"
                {...register('ine')}
                placeholder="INE"
              />
            )}
          </div>
          <div className="CotizadorPersonalDetails-group">
            <input
              autoComplete="off"
              className={classNames('Input', {
                'is-invalid': errors?.monthlyIncome,
              })}
              type="number"
              {...register('monthlyIncome')}
              placeholder="Ingreso mensual"
              min={0}
              step={1000}
            />
            <input
              autoComplete="off"
              className={classNames('Input', {
                'is-invalid': errors?.phone,
              })}
              type="tel"
              {...register('phone')}
              placeholder="Teléfono"
            />
          </div>
          {[CREDIT_TYPE.PENSIONES, CREDIT_TYPE.BANCARIO].includes(creditType) && (
            <div className="CotizadorPersonalDetails-group">
              {creditType === CREDIT_TYPE.BANCARIO && (
                <label for="corroboratedIncome">
                  <input type="checkbox" id="corroboratedIncome" {...register('corroboratedIncome')} />
                  <span>Ingreso mínimo corroborado</span>
                </label>
              )}
              {creditType === CREDIT_TYPE.PENSIONES && (
                <div
                  style={{
                    display: 'flex',
                    gap: 16,
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    columnSpan: 3,
                  }}>
                  <label for="payStubs">
                    <input type="checkbox" id="payStubs" {...register('payStubs')} />
                    <span>Talones de Pago</span>
                  </label>
                  <label for="payChecks">
                    <input type="checkbox" id="payChecks" {...register('payChecks')} />
                    <span>Talones de Cheque</span>
                  </label>
                </div>
              )}
            </div>
          )}
          <div
            style={{
              textAlign: 'right',
            }}>
            Todos los campos son obligatorios
          </div>
        </div>
        <div className="CotizadorWizard-actions">
          <button onClick={goBack} type="button">
            Atrás
          </button>
          <button className="primary" type="submit" disabled={!isValid}>
            Siguiente
          </button>
        </div>
      </form>
    </div>
  );
};
