import './CotizadorSuccessState.scss';

export const CotizadorSuccessState = ({ onFinishClick }) => {
  return (
    <div className="CotizadorSuccessState">
      <h3>
        <b>¡Gracias por confiar en Deesa!</b>
      </h3>
      <p>
        En breve un asesor certificado se comunicará contigo para informarte sobre los precios de nuestras viviendas.
      </p>
      <div className="CotizadorWizard-actions">
        <button onClick={onFinishClick} className="primary">
          Finalizar
        </button>
      </div>
    </div>
  );
};
