import axios from 'axios';

export const axiosCommonConfig = {
  headers: {
    Authorization: `Basic ${process.env.REACT_APP_ODOO_AUTH_TOKEN}`,
  },
};

const axiosClientInstance = axios.create({
  ...(process.env.REACT_APP_CLIENT_API_BASE_URL &&
    process.env.NODE_ENV === 'production' && {
      baseURL: process.env.REACT_APP_CLIENT_API_BASE_URL,
    }),
});

const Odoo = () => {
  const getDevelopments = async () => {
    const response = await axiosClientInstance.get('/api/desarrollo');
    return response.data;
  };

  const getPrototypes = async () => {
    const response = await axiosClientInstance.get('/api/producto');
    return response.data;
  };

  const submitQuotationForm = async (data) => {
    const response = await axiosClientInstance.post(`/api/formulario`, data);
    return response.data;
  };

  return {
    getDevelopments,
    getPrototypes,
    submitQuotationForm,
  };
};

export const OdooAPI = Odoo();
